import React from 'react'
import { graphql } from 'gatsby'
import { Layout } from 'components'
import { Hero, MenuItems } from 'slices/menu'

export const query = graphql`
  query allNewsQuery {
    prismic {
      menu(uid: "news", lang: "en-us") {
        title
        breadcrumb_title
        hero_image
      }
      allNewss(sortBy: published_date_DESC) {
        edges {
          node {
            _meta {
              uid
            }
            title
            hero_image
            menu_image
            published_date
            breadcrumb_title
            hero_title
          }
        }
      }
    }
  }
`

const News = ({
  data: {
    prismic: {
      allNewss: { edges: news },
      menu,
    },
  },
}) => {
  const items = news.map(item => {
    const { _meta: { uid }, hero_title, hero_image, menu_image, title } = item.node

    return {
      link: {
        '_linkType': 'Link.document',
        '__typename': 'PRISMIC_News',
        '_meta': {
          uid
        },
      },
      image: menu_image || hero_image,
      name: hero_title,
      description: title,
    }
  })

  return (
    <Layout>
      <Hero data={menu} />
      <MenuItems data={{ items }} />
    </Layout>
  )
}

News.query = query

export default News
